<template>
  <div class="view work theme theme-yellow">
    <nav-bar name="Work" />

    <!-- IKEA -->
    <div class="case">
      <article>
        <p>IKEA App</p>
        <p>
          Helping make the world’s largest furniture retailer accessible from the palm of your
          hand.
        </p>
      </article>

      <div class="carousel">
        <div class="slide">
          <figure id="ikea-1">
            <video
              :src="require('@/assets/case/ikea-1.mp4')"
              muted
              autoplay
              loop
              playsinline
            />
          </figure>
          <figcaption>
            The app leads with inspiration, bringing to life the iconic catalog images.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/ikea-2.svg')" v-svg-inline />
          </figure>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/ikea-3.png')" />
          </figure>
          <figcaption>
            The complete redesign of the app focused on consistency, simplicity and ease of
            use.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/ikea-4.jpg')" />
          </figure>
          <figcaption>
            Once the app that allowed costumers to shop from home, we quickly got started on
            how the app could benefit the in-store experience.
          </figcaption>
        </div>
        <div class="slide">
          <figure id="ikea-5">
            <video
              :src="require('@/assets/case/ikea-5.mp4')"
              muted
              autoplay
              loop
              playsinline
            />
          </figure>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/ikea-6.svg')" v-svg-inline />
          </figure>
        </div>
      </div>

      <article>
        <p>Role: Design Director</p>
        <p>
          For over two years I was embedded at IKEA and led the design team from initial
          concepts to a realised released product. Then scaling that product to over 40 markets
          around the world.
        </p>
        <p>
          Once launched, the new IKEA app allowed customers to shop both effortlessly at home
          and in stores. Starting with with ability to shop the iconic styled catalogue of IKEA
          inspirational images and later releasing features like Scan & Go; which allowed
          customers to skip the also iconic long checkout lines.
        </p>
      </article>

      <article>
        <List
          :items="[
            {
              name: 'Download app',
              link: 'https://apps.apple.com/dk/app/ikea/id1452164827',
              icon: 'link',
            },
            {
              name: 'More info',
              link: 'https://work.co/clients/ikea/',
              icon: 'link',
            },
          ]"
        />
      </article>
    </div>

    <!-- Apple -->
    <div class="case view theme theme-brown-2">
      <article class="container">
        <p>Today at Apple</p>
        <p>
          Global partnership for the award-winning Today at Apple retail program, including
          flagship digital experiences for customers and employees.
        </p>
      </article>

      <div class="carousel">
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/apple-1.jpg')" />
          </figure>
          <figcaption>
            Today at Apple was a re-imagining of what the in-store experience could be for
            millions of people around the world.
          </figcaption>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/apple-2.png')" />
          </figure>
          <figcaption>
            Responsive web concepts for how people would discover, reserve, and see upcoming
            and past sessions at a store near them.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-stat-1.svg')" v-svg-inline />
          </figure>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-3.jpg')" />
          </figure>
          <figcaption>
            Behind the scenes for photography and video production and direction for assets to
            be used for Today at Apple across web and video walls.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-4.jpg')" />
          </figure>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/apple-5.jpg')" />
          </figure>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/apple-6.jpg')" />
          </figure>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-stat-2.svg')" v-svg-inline />
          </figure>
        </div>
        <div class="slide">
          <figure id="apple-7" class="landscape video">
            <video
              :src="require('@/assets/case/apple-7.mp4')"
              muted
              autoplay
              loop
              playsinline
            />
          </figure>
          <figcaption>
            A screen test of further refined wallOS designs for the flagship stores which
            dispayed the stores schedule of events.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-stat-3.svg')" v-svg-inline />
          </figure>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/apple-8.jpg')" />
          </figure>
          <figcaption>
            Designs for smaller portable screens that Apple employees used to host more
            intimate sessions.
          </figcaption>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/apple-stat-4.svg')" v-svg-inline />
          </figure>
        </div>
      </div>

      <article>
        <p>Role: Design & Art Direction</p>
        <p>
          I was part of a large and talented team tasked with launching the new in-store
          digital experience in flagship Apple stores around the world.
        </p>
        <p>
          This two year project included everything from designing what would be shown on the
          large 40 foot wall displays to the individual smaller screens around the stores where
          employees led workshops and so much more.
        </p>
        <p>
          I was also tasked to help art direct, cast, location scout, and be a part of the
          photo shoot used for the new programs.
        </p>
        <p>This project went on to win the top prize at the Cannes Lions Festival.</p>
      </article>

      <article>
        <List
          :items="[
            {
              name: 'Sign up for a class',
              link: 'https://www.apple.com/today/',
              icon: 'link',
            },
            {
              name: 'More info',
              link: 'https://work.co/news/today-at-apple/',
              icon: 'link',
            },
          ]"
        />
      </article>
    </div>

    <!-- Beeline -->
    <div class="case view theme theme-brown-3">
      <article>
        <p>Beeline</p>
        <p>
          Imagine a mortgage company that is simple, honest and wants the best for you. That’s
          Beeline.
        </p>
      </article>

      <div class="carousel">
        <div class="slide">
          <figure class="landscape family">
            <video
              :src="require('@/assets/case/beeline-hero.mp4')"
              muted
              autoplay
              loop
              playsinline
            />
            <img :src="require('@/assets/case/beeline-hero-2.jpg')" />
            <img :src="require('@/assets/case/beeline-hero-3.jpg')" />
            <img :src="require('@/assets/case/beeline-hero-4.jpg')" />
          </figure>
          <figcaption>
            The complete redesign of the app focused on consistency, simplicity and ease of
            use.
          </figcaption>
        </div>
        <div class="slide">
          <figure id="apple-7" class="landscape video">
            <a href="https://www.youtube.com/watch?v=yKz0rVXw7ik" target="_lank">
              <img :src="require('@/assets/case/beeline-2.jpg')" />
            </a>
            <svg
              width="38"
              height="52"
              viewBox="0 0 38 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M28.8957 25.9997L9.104 12.458V39.5413L28.8957 25.9997Z" fill="white" />
            </svg>
          </figure>
          <figcaption>
            Beeline trailer created to promote the new product and its offerings.
          </figcaption>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/beeline-3.png')" />
          </figure>
        </div>
        <div class="slide">
          <figure>
            <img :src="require('@/assets/case/beeline-4.svg')" v-svg-inline />
          </figure>
        </div>
        <div class="slide">
          <figure class="landscape">
            <img :src="require('@/assets/case/beeline-5.png')" />
          </figure>
        </div>
      </div>

      <article>
        <p>Role: Design Director</p>
        <p>
          This was a quick but fun project aimed at making the undertaking of applying for a
          mortgage a little less painful, and that maybe you could even enjoy the process.
        </p>
        <p>
          Relying heaving on beautiful illustrations by
          <a href="http://joeschlaud.com/" target="_blank">Joe Schlaud</a>, this experience was
          designed to be as simple as possible and get you through a process that typically
          requires hours and hours of headaches.
        </p>
      </article>

      <article>
        <List
          :items="[
            {
              name: 'Visit site',
              link: 'https://makeabeeline.com/',
              icon: 'link',
            },
          ]"
        />
      </article>
    </div>

    <!-- Rest -->
    <div class="case view theme theme-blue">
      <article>
        <p>Other past projects not shown here—</p>
        <p>
          Lunar, YouTube, Vans, The Metropolitan Opera, FOX, Adult Swim, Freeform TV, Virgin
          America Airlines, IKEA Festival, TMZ, DirectTV, Hallo and The Smithsonian Channel.
        </p>
      </article>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import List from "@/components/List";
export default {
  name: "HomeView",
  components: {
    NavBar,
    List,
  },
};
</script>
<style lang="scss" scoped>
.work {
  padding-bottom: 0;
}
.case {
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: calc(var(--gutter-vertical) * 1.5);
  padding-bottom: calc(var(--gutter-vertical) * 1.5);

  &:first-of-type {
    padding-top: 0;
  }

  &:last-child {
    display: flex;
    flex-direction: column;
    article {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

#ikea-1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  video {
    width: 70%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }
}
#ikea-5 {
  display: grid;
  place-items: center;

  video {
    width: 50%;
    border-radius: 22px;
  }
}

.video {
  display: grid;
  place-items: center;
  video {
    width: 85%;
    aspect-ratio: 16/9;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
  }
}

.carousel {
  margin-top: calc(var(--gutter-vertical) * 2);
  display: flex;
  flex-wrap: nowrap;
  gap: 2px;
  overflow-x: auto;
  padding-bottom: max(100px, calc(100 / 1920 * 100vw));
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  /* scroll-snap-type: x proximity; */

  &::-webkit-scrollbar {
    display: none;
  }

  .slide {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    /* scroll-snap-align: center; */

    figure {
      height: 65vh;
      aspect-ratio: 2/3;
      background: rgba(black, 0.07);
      border-radius: var(--border-radius);
      position: relative;
      overflow: hidden;

      svg {
        width: 100%;
      }

      &:not(.family) img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        object-fit: cover;
        object-position: center;
      }

      &.family {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 14px;
        padding-left: calc(50 / 753 * 100%);

        img,
        video {
          width: auto;
          height: calc(388 / 502 * 100%);
        }
        video {
          border-radius: var(--border-radius);
        }
      }

      &.landscape {
        aspect-ratio: 3/2;
      }
    }

    figcaption {
      max-width: 100%;
      position: absolute;
      bottom: 0px;
      transform: translateY(calc(100% + max(20px, calc(20 / 1920 * 100vw))));
      max-width: max(360px, calc(360 / 1920 * 100vw));
    }
  }
}
</style>
