<template>
  <nav :class="{ hide: hide }">
    <ul>
      <li v-for="item in items" :key="item.name">
        <router-link v-if="item.route" :to="item.route">{{ item.name }}</router-link>
        <a v-if="item.link" :href="item.link" target="_blank">{{ item.name }}</a>
        <icon :name="item.icon" />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    hide: Boolean,
    items: {
      type: Array,
      default() {
        return [
          {
            name: "About",
            route: {
              name: "about",
            },
            icon: "arrow",
          },
          {
            name: "Work",
            route: {
              name: "work",
            },
            icon: "arrow",
          },
          {
            name: "Contact",
            route: {
              name: "contact",
            },
            icon: "arrow",
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  ul {
    li {
      display: flex;
      align-items: center;
      position: relative;

      a {
        padding: var(--gutter-vertical) 0;
        flex: 1;
      }

      &:not(:first-child) {
        border-top: 2px solid var(--color-fg);
      }

      :last-child {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
