<template>
  <div class="view home" :class="classes">
    <h1 @click="clickOnPaul" :class="{ move: subRouteOpen }">Paul<br />Samples</h1>
    <List v-show="!subRouteOpen" />
  </div>

  <main>
    <router-view v-slot="{ Component }">
      <transition name="slide">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
import List from "@/components/List";
export default {
  components: {
    List,
  },
  data() {
    return {
      themes: ["white", "black", "dark-yellow", "dark-brown", "gradient", "green"],
      themeIndex: 0,
    };
  },
  methods: {
    clickOnPaul(e) {
      if (!this.subRouteOpen) {
        document.body.classList.remove(`theme-${this.theme}`);
        if (this.themeIndex + 1 > this.themes.length - 1) {
          this.themeIndex = 0;
        } else {
          this.themeIndex++;
        }
        this.$nextTick(() => {
          document.body.classList.add(`theme-${this.theme}`);
        });
      } else {
        this.$router.push({ name: "home" });
      }
    },
  },
  computed: {
    subRouteOpen() {
      return this.$route.path !== "/";
    },
    theme() {
      return this.themes[this.themeIndex];
    },
    classes() {
      return {
        [`theme-${this.theme}`]: this.theme,
      };
    },
  },
};
</script>

<style src="./css/reset.css" />
<style src="./css/typography.css" />
<style src="./css/themes.css" />
<style src="./css/helpers.css" />
<style src="./css/transitions.css" />

<style lang="scss">
:root {
  --gutter: max(20px, calc(40 / 1920 * 100vw));
  --gutter-vertical: calc(var(--gutter) * (35 / 40));
  --margin-top: calc(var(--gutter) * 3);
  --vh: calc(100svh - var(--margin-top));

  --z-index-nav: 10;

  --border-radius: max(20px, calc(20 / 1920 * 100vw));

  --transition-duration-default: 1s;
  --transition-duration-fast: 0.6s;
  --transition-ease-drawer: cubic-bezier(0.23, 1, 0.32, 1);
}

article {
  /* max-width: 676px; */
  margin-top: calc(var(--gutter-vertical) * 1.5);
  max-width: max(676px, calc(676 / 1920 * 100vw));
}

h1 {
  margin-top: calc(var(--gutter) * 3);
  cursor: pointer;
  user-select: none;
  transition: transform var(--transition-duration-fast) var(--transition-ease-drawer);

  &.move {
    transform: translateY(-87%);
    cursor: pointer;
  }
}

p {
  + p {
    margin-top: var(--gutter-vertical);
  }
}

img {
  width: 100%;
  border-radius: var(--border-radius);
}

a {
  text-decoration: none;
  color: inherit;
}

.home {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.view {
  min-height: var(--vh);
  padding: 0 var(--gutter);
  padding-bottom: var(--gutter-vertical);
  will-change: transform;
}

main {
  position: relative;
  margin-top: var(--margin-top);
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas: "main main main . . image image .";
  gap: calc(var(--gutter) / 2);

  .container {
    grid-area: main;
  }

  @media (max-width: 1440px) {
    grid-template-areas: "main main main main . image image image";

    article {
      max-width: 676px;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas: "main";
    display: block;
    article {
      max-width: 676px;
    }
  }
}
</style>
