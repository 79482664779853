<template>
  <div class="view about theme theme-blue">
    <nav-bar name="About" />
    <div class="grid">
      <div class="container">
        <article>
          <p>
            Paul Samples is an American designer and dad based in Copenhagen with over a decade
            of experience helping create digital products for brands like Apple, IKEA, YouTube
            and more.
          </p>
        </article>
        <article class="hero">
          <img :src="require('@/assets/img/paul-2.jpg')" />
        </article>
        <article>
          <p>
            I’m interested in creating products that enhance peoples lives. I’m not interested
            in creating products that distract from them. I strongly believe that good product
            design should require little effort and even less time to be used effectively.
          </p>
        </article>

        <article>
          <p>Work experience</p>
        </article>

        <article>
          <p>
            Present – 2014<br />
            Work & Co.<br />
            Assoc. Design Director
          </p>
          <p>
            This is where I learned everything. Some of the most talented and kindest people
            are here. I’ve helped create digital products for IKEA App, Today at Apple, Lunar,
            Beeline, The Smithsonian Channel, YouTube, FOX, TMZ News, The Met Opera and more.
          </p>
        </article>

        <article>
          <p>
            2014 – 2013<br />
            Ralph Appelbaum<br />
            Exhibit Designer
          </p>
          <p>
            I worked on a small team of incredible designers helping shape from the ground up a
            new Smithsonian museum in Washington, DC—The National Museum of African American
            History & Culture. I learned more at this job than any history class I’ve ever
            taken.
          </p>
        </article>

        <article>
          <p>
            2013 – 2011<br />
            Frog Design<br />
            Designer
          </p>
          <p>
            I moved to NYC just after graduating college and landed the opportunity to work
            for, at the time, one of the most respected design firms. I learned so much here
            and more importantly, I met some incredible people that would go on to lead me
            where I am today.
          </p>
        </article>

        <article>
          <list-contact />
        </article>
      </div>
      <div class="image">
        <img :src="require('@/assets/img/paul-2.jpg')" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ListContact from "@/components/ListContact";

export default {
  name: "AboutView",
  components: {
    NavBar,
    ListContact,
  },
};
</script>
<style lang="scss" scoped>
.about {
  .image {
    grid-area: image;
    height: calc(var(--vh) - var(--margin-top));
    display: flex;
    align-items: center;
  }

  @media (max-width: 1024px) {
    img {
      max-width: 320px;
    }
    .image {
      display: none;
    }
  }

  .hero {
    @media (min-width: 1025px) {
      display: none;
    }
  }
}
</style>
