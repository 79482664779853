<template>
  <img :src="src" v-svg-inline />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "burger",
    },
  },
  computed: {
    src() {
      return require(`@/assets/svg/${this.name}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  --icon: max(24px, calc(24 / 1920 * 100vw));
  width: var(--icon);
  height: var(--icon);

  &:focus {
    outline: none;
  }
}
</style>
