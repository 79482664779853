import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueSvgInlinePlugin from "vue-svg-inline-plugin";
import Icon from "@/components/Icon";

const app = createApp(App);

app.use(VueSvgInlinePlugin).component("Icon", Icon).use(store).use(router).mount("#app");
