<template>
  <list :items="items" />
</template>
<script>
import List from "@/components/List";

export default {
  components: {
    List,
  },
  data() {
    return {
      items: [
        {
          name: "Email",
          link: "mailto:paulsamples@me.com",
          icon: "link",
        },
        {
          name: "LinkedIn",
          link: "https://www.linkedin.com/in/paul-samples",
          icon: "link",
        },
        {
          name: "CV",
          link: process.env.BASE_URL + "Paul-Samples-CV.pdf",
          icon: "download",
        },
      ],
    };
  },
};
</script>
