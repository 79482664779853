<template>
  <div class="view about theme theme-brown">
    <nav-bar name="Contact" />
    <list-contact />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import ListContact from "@/components/ListContact";

export default {
  name: "ContatView",
  components: {
    NavBar,
    ListContact,
  },
};
</script>
<style lang="scss" scoped>
.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
