<template>
  <header>
    <h2>{{ name }}</h2>
  </header>
  <figure @click="goBack">
    <icon name="arrow" />
  </figure>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "Title",
    },
  },
  methods: {
    goBack() {
      window.scrollTo(0, 0);
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
header,
figure {
  padding: var(--gutter-vertical) 0;

  display: flex;
  z-index: var(--z-index-nav);
}
header {
  position: absolute;
  top: 0;
}
figure {
  align-items: center;
  position: sticky;
  top: 0;
  transform: rotate(180deg);
  cursor: pointer;
}
</style>
